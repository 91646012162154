<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='addNewInvestment'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Add New Investment</h5>
            <a class="close" title="Close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Investment Id</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.investment_id"
                              class="form-control"
                              id="investment_id"
                              placeholder="id"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              v-model="formData.date"
                              class="form-control"
                              id="investment_date"
                              required
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Account Name *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select class="form-control" id="investment_account_name" v-model="formData.account_id" required>
                              <option value selected disabled>Account Head</option>
                              <option
                                v-for="(account,index) in investmentElements.accounts"
                                :key="index"
                                :value="account.id"
                              >{{account.account_name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              id="investment_description"
                              v-model="formData.description"
                              style="height: 4.85rem;"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Payment Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.branch_id"
                              class="form-control"
                              id="investment_branch_name"
                              required
                              @change="getPayments"
                              :disabled="branchDisabled"
                            >
                              <option value selected disabled>Select Branch</option>
                              <option
                                v-for="(method,index) in investmentElements.branchs"
                                :key="index"
                                :value="method.id"
                              >{{method.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payment Method *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.payment_method_id"
                              class="form-control"
                              id="investment_payment_method"
                              required
                              @change="getBanks()"
                            >
                              <option value selected disabled>Select Method</option>
                              <option
                                v-for="(method,index) in payement_methods"
                                :key="index"
                                :value="method.id"
                              >{{method.title}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.bank_id"
                              class="form-control"
                              id="investment_bank_name"
                              required
                              :disabled="bank_disabled"
                            >
                              <option value selected disabled>Select Bank</option>
                              <option
                                v-for="(bank,index) in banks"
                                :key="index"
                                :value="bank.id"
                              >{{bank.bank}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <!-- Transaction id is replaced with Ref. -->
                          <div class="col-md-5 mr-0 pr-0">Ref.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="input"
                              v-model="formData.transaction_id"
                              class="form-control"
                              placeholder="Payment Ref."
                              id="investment_transaction_id"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Investment Amt. *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              min="1"
                              class="form-control"
                              id="investment_amount"
                              v-model="formData.amount"
                              placeholder="0.00"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :disabled="disableSubmitButton"
                >{{(disableSubmitButton)?"Submitting...":"Submit"}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  data() {
    return {
      formData: {
        investment_id: "",
        date: this.today,
        account_type_id: "",
        account_id: "",
        investment_type: 0,
        payment_method_id: "",
        bank_id: "",
        branch_id: "",
        transaction_id: null,
        amount: null,
        description: null,
      },
      disableSubmitButton: false,
      bank_disabled: true,
      payement_methods: [],
      banks: [],
      branchDisabled:false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("investments", ["investmentLists", "investmentElements"]),
  },
  mounted() {
    this.formData.date = this.today;
  },
  methods: {
    submit() {
      this.disableSubmitButton = true;
      Services.createNewInvestment(this.formData)
        .then((res) => {
          this.$store.commit(
            "investments/setInvestmentLists",
            res.data.data.data
          );
          this.$store.commit("setDataId", res.data.data.id);
          this.$store.dispatch("modalWithMode", {
            id: "investmentSlip",
            mode: "read",
          });
          this.$emit("parent-event");
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let error = Object.values(err.response.data.errors).flat();
            this.setNotification(error);
          } else {
            let error = err.response.data.error_message;
            this.setNotification(error);
          }
        })
        .finally(() => {
          this.disableSubmitButton = false;
        });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    getInvestmentId() {
      Services.getInvestmentId()
        .then((res) => {
          this.formData.investment_id = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPayments() {
      this.bank_disabled = true;
      this.formData.bank_id = "";
      if (this.investmentElements.payment_methods.length != 0) {
        let details = [];
        let methods = this.formData.branch_id;
        details = this.investmentElements.payment_methods.filter(function (
          data
        ) {
          return data.branch == methods;
        });
        this.payement_methods = { ...details };
        this.formData.payment_method_id = this.payement_methods[0].id;
      }
    },
    getBanks() {
      let payment_bank = this.formData.payment_method_id;
      let values = this.investmentElements.payment_methods.find(function (
        data
      ) {
        if (data.id == payment_bank) {
          return data.title;
        }
      });
      if (values.title.toLowerCase() == "bank") {
        if (this.investmentElements.banks.length != 0) {
          this.bank_disabled = false;
          let details = [];
          let methods = this.formData.branch_id;
          details = this.investmentElements.banks.filter(function (data) {
            return data.branch == methods;
          });
          this.banks = { ...details };
          if (details.length > 0) {
            this.formData.bank_id = details[0].id;
          } else {
            this.formData.bank_id = "";
          }
        } else {
          this.bank_disabled = false;
          this.formData.bank_id = "";
        }
      } else {
        this.bank_disabled = true;
        this.formData.bank_id = "";
      }
    },
    clearData() {
      this.formData.investment_id = "";
      this.formData.date = this.today;
      this.formData.branchDisabled=false,
      this.formData.account_type_id = "";
      this.formData.account_id = "";
      this.formData.investment_type = 0;
      this.formData.payment_method_id = "";
      this.formData.bank_id = "";
      this.formData.branch_id = "";
      this.formData.transaction_id = null;
      this.formData.amount = null;
      this.formData.description = null;
      this.disableSubmitButton = false;
      this.bank_disabled = true;
      this.payement_methods = [];
      this.banks = [];
    },
  },
  watch: {
    modalId(value) {
      if (value == "addNewInvestment") {
        this.clearData();
        Services.getInvestmentElements()
          .then((res) => {
            this.$store.commit(
              "investments/setInvestmentElements",
              res.data.data
            );
            this.getInvestmentId();
             if(res.data.data.branchs.length == 1)
              {
                this.formData.branch_id = res.data.data.branchs[0].id;
                this.branchDisabled = true;
                this.getPayments();
              }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
