<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-4">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Account</a>
                </li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">Investment</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Investment</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-8 tx-right">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mr-0 mr-md-2 mg-b-0"
          v-if="checkSinglePermission('add_investment')"
          @click="$store.dispatch('modalWithMode', { id: 'addNewInvestment', mode: 'create' })"
        >Add Investment</button>
        <button
          type="button"
          class="btn btn-primary mg-t-8 mr-0 mb-2 mb-md-0"
          v-if="checkSinglePermission('withdraw_investment')"
          @click="$store.dispatch('modalWithMode', { id: 'addWithDraw', mode: 'draw' })"
        >Withdraw Investment</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select v-model="params.pageIndex" id="investment_filter_size" @change="searchFilter()" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select v-model="params.type" class="mr-2 mg-t-5" id="investment_filter_type" @change="searchFilter">
            <option value>All</option>
            <option value="0">Added</option>
            <option value="1">Withdrawal</option>
          </select>
          <SearchDayMonthComponent @clicked="monthDayClicked"></SearchDayMonthComponent>
          <div class="mr-2 d-inline-block">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input type="date" v-model="params.from_date" placeholder="From" class="filter-input mr-1 mg-t-5" id="investment_filter_date_from"  @change="searchFilter"/>
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input type="date" v-model="params.to_date" placeholder="To" class="filter-input mr-1 mg-t-5" id="investment_filter_date_to"  @change="searchFilter"/>
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input type="text" v-model="params.searched" class="float-right" id="investment_search" placeholder="Search..." @keyup="searchKeyword" />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Investment ID</th>
              <th class="wd-5p">Type</th>
              <th class="wd-20p">Account Name</th>
              <th class="wd-10p">Payment Mode</th>
              <th class="wd-10p">Investment Amt.</th>
              <th class="wd-10p text-center table-end-item" v-if="checkIfAnyPermission(['view_investment','edit_investment','edit_withdraw_investment','cancel_investment'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && investmentLists.length > 0">
            <tr v-for="(investment, index) in investmentLists" :key="index">
              <th scope="row" class="table-start-account">{{ pageSerialNo+index }}.</th>
              <td>{{investment.date}}</td>
              <td>#{{investment.investment_id}} <span v-if="investment.status == 1" class="tx-danger">-Canceled</span></td>
              <td>{{investment.investment_type==1?'Withdraw':'Add'}}</td>
              <td>{{investment.account.account_name}}</td>
              <td>{{investment.payment_method.title}} <span v-if="investment.bank_id">-{{investment.bank.bank}}</span></td>
              <td>{{parseDigitForSlip(investment.amount)}}</td>
              <td class="text-right table-end-item" v-if="checkIfAnyPermission(['view_investment','edit_investment','edit_withdraw_investment','cancel_investment'])">
                <a href="javascript:;" class="mr-3" v-if="checkSinglePermission('view_investment')" :id="'investmentView'+investment.id" @click="view('investmentSlip',investment.id)" title="View">
                  <i class="fas fa-eye tx-success"></i>
                </a>
                <a
                  href="javascript:;"
                  class="mr-3"
                  :id="'investmentEdit'+investment.id"
                  title="Edit"
                  v-if="investment.status == 0 && checkIfAnyPermission(['edit_investment','edit_withdraw_investment'])"
                  @click="edit((investment.investment_type==0)?'editInvestment':'editWithDraw', investment.id)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a href="javascript:;" v-if="investment.status == 0 && checkSinglePermission('cancel_investment')" @click="cancel(investment.id)" :id="'investmentCancel'+investment.id" title="Cancel">
                  <i class="fas fa-times-circle tx-danger"></i>
                </a>
              </td>
            </tr>
          </tbody>
           <tbody v-else-if="error">
            <td colspan="11" class="text-center">{{error_message}}</td>
          </tbody>
          <tbody v-else-if="!loading && investmentLists.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate 
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
        <InvestmentCreate @parent-event="getData" />
        <InvestmentEdit @parent-event="getData" />
        <Withdraw @parent-event="getData" />
        <EditWithdraw @parent-event="getData" />
        <Slip />
      </div>
    </div>
  </div>
</template>
<script>
import InvestmentCreate from "./create";
import InvestmentEdit from "./edit";
import Withdraw from "./withdrawal";
import EditWithdraw from "./editWithdraw";
import Slip from "./SlipAdd";
import { mapGetters } from "vuex";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import Services from "./Services/Services";
import Paginate from 'vuejs-paginate';
import _ from 'lodash';
export default {
  components: {
    InvestmentCreate,
    InvestmentEdit,
    Withdraw,
    SearchDayMonthComponent,
    Paginate,
    Slip,
    EditWithdraw,
  },
  data() {
    return {
      params: {
        pageIndex: 10,
        searched: "",
        offset: 0,
        branch: "",
        type: "",
        from_date: "",
        to_date: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      totalPageCount: 0,
      pageSerialNo: 1,
      loading: true,
      error: false,
      error_message:'',
      page: 1,
    };
  },
  methods: {
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.pageIndex + 1;
      }
      this.offset = (page - 1) * this.params.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    searchFilter(){
        this.page=1;
        this.pageSerialNo=1;
        this.offset = 0;
        this.getFilteredDataWithoutDates();
    },
    monthDayClicked(value) {
      if (value) {
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    searchKeyword: _.debounce(function () {
        this.page=1;
        this.pageSerialNo=1;
        this.offset = 0;
      this.getFilteredDataWithoutDates();
    }, 1000),
    getFilteredData() {
      this.loading = true;
      this.page=1;
      let params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        today: this.params.day,
        month: this.params.month + 1,
        type: this.params.type,
        year: this.params.year,
        from_date: this.params.from_date,
        to_date: this.params.to_date,
        searched: this.params.searched,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        type: this.params.type,
        branch: this.params.branch,
        today: "",
        month: "",
        year: this.params.year,
        from_date: this.params.from_date,
        to_date: this.params.to_date,
        searched: this.params.searched,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.loading = true;
      Services.getInvestmentList(params)
        .then((res) => {
          this.$store.commit("investments/setInvestmentLists", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          this.$store.commit("investments/setInvestmentLists", []);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "view" });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    cancel(id) {
      this.$swal({
        title: "Do you really want to cancel this Investment ?",
        html: `<label>Remarks: </label>
                <textarea id="cancel-remarks" class="swal2-input"></textarea>
            `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let remarks = document.getElementById("cancel-remarks").value;
          // cancel the payin with remarks
          return Services.cancelInvestment(id, { remarks: remarks })
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              this.getData();
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }else{
                this.setNotification(error);
              }

            });
          
        },
      });
    },
    getData() {
      this.getFilteredDataWithoutDates();
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("investments", ["investmentLists", "investmentElements"]),
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
</style>
